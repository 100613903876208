/* eslint-disable jsx-a11y/anchor-is-valid */
import { connect } from 'react-redux';
import React from 'react';
import { Link } from 'react-router-dom';
import Menu, { MenuItem } from 'material-ui/Menu';
import ListSubheader from 'material-ui/List/ListSubheader';
import List, { ListItemText } from 'material-ui/List';
import Collapse from 'material-ui/transitions/Collapse';
import Divider from 'material-ui/Divider';
import ExpandLess from 'material-ui-icons/ExpandLess';
import ExpandMore from 'material-ui-icons/ExpandMore';

import Logotipo from '../static/logotipo_fato.png';

import './Header.scss';

const mapStateToProps = (state, props) => ({
  cesta: state.cesta.reduce((acc, val) => acc + val.units, 0),
});

const pStyle = {
  paddingLeft: '32px',
};

class Header extends React.Component {
  state = {
    anchorEl: null,
    open: false,
    menuMobile: false,
  };
  toggleMenu = event => {
    this.setState({ menuMobile: event.currentTarget });
  };
  handleClick = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  toggleSubMenu = () => {
    this.setState({ open: !this.state.open });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, menuMobile: null });
  };
  render() {
    const { cesta } = this.props;
    const { anchorEl, menuMobile } = this.state;
    return (
      <header>
        <div className="header__top">
          <div className="container">
            <p>
              <i className="material-icons">phone</i> +1 (703) 989-5883‬
            </p>
          </div>
        </div>
        <div className="container">
          <div className="main-menu">
            <Link className="logotipo" to="/">
              <img src={Logotipo} alt="logotipo" style={{ maxWidth: 100 }} />
            </Link>
            <button
              className="btn btn-menu"
              aria-owns={menuMobile ? 'menu' : null}
              aria-haspopup="true"
              onClick={this.toggleMenu}
            >
              MENU
            </button>
            <nav id="nav-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <a
                  aria-owns={anchorEl ? 'simple-menu' : null}
                  aria-haspopup="true"
                  onClick={this.handleClick}
                >
                  Shipping Calculator <i className="material-icons">&#xE313;</i>
                </a>
              </li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/cart">
                  Cart <div className="contador--cesta">{cesta}</div>
                </Link>
              </li>
            </nav>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={this.handleClose}
            >
              <Link to="/shipping/boxes">
                <MenuItem onClick={this.handleClose}>Boxes</MenuItem>
              </Link>
              <Link to="/shipping/suitcases">
                <MenuItem onClick={this.handleClose}>Suitcases</MenuItem>
              </Link>
              <Link to="/shipping/pets">
                <MenuItem onClick={this.handleClose}>Pets</MenuItem>
              </Link>
              <MenuItem onClick={this.toggleSubMenu}>
                <ListItemText primary="Others" />
                {this.state.open ? <ExpandLess /> : <ExpandMore />}
              </MenuItem>
              <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                <Link to="/shipping/matress">
                  <MenuItem onClick={this.handleClose} style={pStyle}>
                    Matress
                  </MenuItem>
                </Link>
                <Link to="/shipping/televisions">
                  <MenuItem onClick={this.handleClose} style={pStyle}>
                    Televisions
                  </MenuItem>
                </Link>
                <Link to="/shipping/others">
                  <MenuItem onClick={this.handleClose} style={pStyle}>
                    More
                  </MenuItem>
                </Link>
              </Collapse>
            </Menu>
            <Menu
              id="menu"
              anchorEl={menuMobile}
              open={Boolean(menuMobile)}
              onClose={this.handleClose}
            >
              <List className="menu">
                <Link to="/">
                  <MenuItem onClick={this.handleClose}>Home</MenuItem>
                </Link>
                <Divider />
                <ListSubheader
                  style={{ background: '#FFF', padding: '0 16px' }}
                >
                  Shipping Calculator
                </ListSubheader>
                <Link to="/shipping/boxes">
                  <MenuItem onClick={this.handleClose}>Boxes</MenuItem>
                </Link>
                <Link to="/shipping/suitcases">
                  <MenuItem onClick={this.handleClose}>Suitcases</MenuItem>
                </Link>
                <Link to="/shipping/pets">
                  <MenuItem onClick={this.handleClose}>Pets</MenuItem>
                </Link>
                <MenuItem onClick={this.toggleSubMenu}>
                  <ListItemText primary="Others" />
                  {this.state.open ? <ExpandLess /> : <ExpandMore />}
                </MenuItem>
                <Collapse in={this.state.open} timeout="auto" unmountOnExit>
                  <Link to="/shipping/matress">
                    <MenuItem onClick={this.handleClose} style={pStyle}>
                      Matress
                    </MenuItem>
                  </Link>
                  <Link to="/shipping/televisions">
                    <MenuItem onClick={this.handleClose} style={pStyle}>
                      Televisions
                    </MenuItem>
                  </Link>
                  <Link to="/shipping/others">
                    <MenuItem onClick={this.handleClose} style={pStyle}>
                      More
                    </MenuItem>
                  </Link>
                </Collapse>
                <Divider />
                <Link to="/contact">
                  <MenuItem onClick={this.handleClose}>Contact</MenuItem>
                </Link>
                <Divider />
                <Link to="/cart">
                  <MenuItem onClick={this.handleClose}>
                    Cart <div className="contador--cesta">{cesta}</div>
                  </MenuItem>
                </Link>
              </List>
            </Menu>
          </div>
        </div>
      </header>
    );
  }
}

export default connect(mapStateToProps)(Header);
