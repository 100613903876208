export const addProduct = (type, description, price, units) => ({
  type: 'ADD_PRODUCT',
  payload: {
    type,
    description,
    price,
    units,
  },
})

export const removeProduct = (id) => ({
  type: 'REMOVE_PRODUCT',
  payload: {
    id,
  },
})

export const removeAll = (id) => ({
  type: 'REMOVE_ALL',
})

export const updateProduct = (product) => ({
  type: 'UPDATE_PRODUCT',
  payload: product,
})

export const updateRate = (payload) => ({
  type: 'UPDATE_RATE',
  payload: payload,
})

export const removePets = () => ({
  type: 'REMOVE_PETS',
})

export const setListOfProducts = (products) => ({
  type: 'SET_LIST_OF_PRODUCTS',
  payload: products,
})

export const setListOfRates = (products) => ({
  type: 'SET_LIST_OF_RATES',
  payload: products,
})
