import { createStore } from 'redux'
import reducers from './reducers'

const initialState = {
  cesta: [],
  rate: {
    origen: '',
    destination: '',
    value: 0,
  },
}

const store = createStore(reducers, initialState)

export default store
