import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import Loadable from 'react-loadable'
import { useDispatch } from 'react-redux'
import { BrowserRouter as Router, Route } from 'react-router-dom'

import { setListOfProducts, setListOfRates } from './accions'
import Header from './components/Header'
import Footer from './components/Footer'
import './main.scss'

const Loading = () => (
  <div className="container mt-1">
    <p>Loading ...</p>
  </div>
)

const Home = Loadable({
  loader: () => import(/* webpackChunkName: "home" */ './pages/Home'),
  loading: Loading,
})

const Cart = Loadable({
  loader: () => import(/* webpackChunkName: "cart" */ './pages/Cart'),
  loading: Loading,
})

const Contact = Loadable({
  loader: () => import(/* webpackChunkName: "contact" */ './pages/Contact'),
  loading: Loading,
})

const Destinations = Loadable({
  loader: () =>
    import(/* webpackChunkName: "destinations" */ './pages/Destinations'),
  loading: Loading,
})

const Prohibited = Loadable({
  loader: () =>
    import(/* webpackChunkName: "prohibited" */ './pages/Prohibited'),
  loading: Loading,
})

const Shipping = Loadable({
  loader: () => import(/* webpackChunkName: "shipping" */ './pages/Shipping'),
  loading: Loading,
})

const ShippingConditions = Loadable({
  loader: () =>
    import(
      /* webpackChunkName: "shippingConditions" */ './pages/ShippingConditions'
    ),
  loading: Loading,
})

const ShippingWeight = Loadable({
  loader: () =>
    import(/* webpackChunkName: "shippingWeight" */ './pages/ShippingWeight'),
  loading: Loading,
})

const App = () => {
  const dispatch = useDispatch()

  useEffect(() => {
    const promise = (path) => fetch(path).then((res) => res.json())
    Promise.all([promise('/products.json'), promise('/rates_v2.json')]).then(
      ([products, rates]) => {
        dispatch(setListOfProducts(products))
        dispatch(setListOfRates(rates))
      },
    )
  }, [dispatch])

  return (
    <Router>
      <Route
        render={() => {
          window.scrollTo(0, 0)
          return (
            <div>
              <Helmet>
                <meta charSet="utf-8" />
                <meta
                  name="description"
                  content="Personal Effects Saudia Cargo"
                />
                <title>Personal Effects Saudia</title>
                <link
                  href="https://fonts.googleapis.com/icon?family=Material+Icons"
                  rel="stylesheet"
                />
              </Helmet>
              <Header />
              <div>
                <Route exact path="/" component={Home} />
                <Route path="/cart" component={Cart} />
                <Route path="/contact" component={Contact} />
                <Route path="/destinations" component={Destinations} />
                <Route path="/prohibited-items" component={Prohibited} />
                <Route path="/shipping" component={Shipping} />
                <Route
                  path="/shipping-conditions"
                  component={ShippingConditions}
                />
                <Route path="/shipping-weight" component={ShippingWeight} />
              </div>
              <Footer />
            </div>
          )
        }}
      />
    </Router>
  )
}

export default App
