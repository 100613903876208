import { combineReducers } from 'redux'

let idProduct = 0

const cesta = (state = [], action) => {
  const { payload } = action
  switch (action.type) {
    case 'ADD_PRODUCT':
      idProduct++
      return [...state, { id: idProduct, ...payload }]
    case 'REMOVE_PRODUCT':
      return state.filter((product) => product.id !== payload.id)
    case 'UPDATE_PRODUCT':
      return state.map((product) =>
        product.id === payload.id ? { ...product, ...payload } : product,
      )
    case 'REMOVE_PETS':
      return state.filter((product) => product.type !== 'pets')
    case 'REMOVE_ALL':
      return []
    default:
      return state
  }
}

const rate = (state = {}, action) => {
  switch (action.type) {
    case 'UPDATE_RATE':
      return { ...state, ...action.payload }
    default:
      return state
  }
}

const products = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIST_OF_PRODUCTS':
      return action.payload
    default:
      return state
  }
}

const rates = (state = [], action) => {
  switch (action.type) {
    case 'SET_LIST_OF_RATES':
      return action.payload
    default:
      return state
  }
}

const reducers = combineReducers({
  cesta,
  products,
  rate,
  rates,
})

export default reducers
