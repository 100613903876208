/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from 'react-router-dom'
import Menu, { MenuItem } from 'material-ui/Menu'
import cardVisa from '../static/visa.svg'
import cardMasterCard from '../static/mastercard.svg'
import cardAmerican from '../static/american.svg'
import cardDiscover from '../static/discover.svg'
import cardPaypal from '../static/paypal.svg'
import './Footer.scss'

class Footer extends React.Component {
  state = {
    anchorEl: null,
  }
  handleClick = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }
  handleClose = () => {
    this.setState({ anchorEl: null })
  }
  render() {
    const { anchorEl } = this.state
    return (
      <footer>
        <div className="container">
          <div className="main-footer">
            <div className="row">
              <div className="col-sm">
                <h5>Shipping</h5>
                <ul>
                  <li>
                    <Link to="/shipping/boxes">Boxes</Link>
                  </li>
                  <li>
                    <Link to="/shipping/suitcases">Suitcases</Link>
                  </li>
                  <li>
                    <Link to="/shipping/pets">Pets</Link>
                  </li>
                  <li>
                    <a
                      role="menuitem"
                      aria-owns={anchorEl ? 'simple-menu' : null}
                      aria-haspopup="true"
                      onClick={this.handleClick}
                    >
                      Others
                    </a>
                  </li>
                </ul>
              </div>
              <div className="col-sm">
                <h5>Shipping policies</h5>
                <ul>
                  <li>
                    <a
                      href={
                        process.env.PUBLIC_URL + '/AVI_Information_Pets_v2.pdf'
                      }
                      target="_black"
                      rel="noopener noreferrer"
                    >
                      Pets/ live animals/ AVI information
                    </a>
                  </li>
                  <li>
                    <Link to="/prohibited-items">Prohibited Items</Link>
                  </li>
                  <li>
                    <Link to="/shipping-conditions">Shipping Conditions</Link>
                  </li>
                  <li>
                    <Link to="/shipping-weight">
                      How to Calculate Your Shipment Weight
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm">
                <h5>Pages</h5>
                <ul>
                  <li>
                    <Link to="/contact">Contact</Link>
                  </li>
                  <li>
                    <Link to="/cart">Cart</Link>
                  </li>
                  <li>
                    <Link to="/destinations">Destinations</Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm">
                <h5>Links of interest</h5>
                <ul>
                  <li>
                    <a
                      href={
                        process.env.PUBLIC_URL +
                        '/Shippers_Security_Endoresment.pdf'
                      }
                      target="_black"
                      rel="noopener noreferrer"
                    >
                      Shippers Security Endoresment
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://saudiacargo.com/E-SERVICES/SHIPMENT-TRACKING.aspx"
                      target="_black"
                    >
                      Track Your Shipment
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://saudiacargo.com/NETWORK-FLEET/NETWORK.aspx"
                      target="_black"
                    >
                      Network
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          <Link to="/shipping/matress">
            <MenuItem onClick={this.handleClose}>Matress</MenuItem>
          </Link>
          <Link to="/shipping/televisions">
            <MenuItem onClick={this.handleClose}>Televisions</MenuItem>
          </Link>
          <Link to="/shipping/others">
            <MenuItem onClick={this.handleClose}>More</MenuItem>
          </Link>
        </Menu>
        <div className="footer__bottom">
          <div className="container">
            <div className="footer__bottom-content">
              <p>© 2018 Fato Cargo</p>
              <ul className="credit-card">
                <li>
                  <img src={cardPaypal} alt="paypal" />
                </li>
                <li>
                  <img src={cardVisa} alt="visa" />
                </li>
                <li>
                  <img src={cardMasterCard} alt="master card" />
                </li>
                <li>
                  <img src={cardDiscover} alt="discover" />
                </li>
                <li>
                  <img src={cardAmerican} alt="american express" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
